<template>
  <div class="speaker">
    <HeroVideo />
    <div class="speaker-text text-center py-4">
      <h2>Speakers</h2>
    </div>
    <div class="content py-5">
      <div class="container-xl">
        <div class="goh">
          <div class="head py-4 text-center rounded">
            <h2>Guest of Honour</h2>
          </div>
          <div class="row py-5">
            <div class="col-sm-4 col-6 mx-auto text-center">
              <img src="/speakers/desktop/GOH/1.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div class="host">
          <div class="head py-4 text-center rounded">
            <h2>Co-Hosts</h2>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/co host/popup/1')"
            >
              <img
                src="/speakers/desktop/co host/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/co host/popup/2')"
            >
              <img
                src="/speakers/desktop/co host/2.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="centerstage-1">
          <div class="head py-4 text-center rounded">
            <h2>Looking Across the Bridge</h2>
            <p>CENTRESTAGE SPEAKERS</p>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="
                openModal('/speakers/desktop/CentreStage Speakers/popup/1')
              "
            >
              <img
                src="/speakers/desktop/CentreStage Speakers/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="
                openModal('/speakers/desktop/CentreStage Speakers/popup/2')
              "
            >
              <img
                src="/speakers/desktop/CentreStage Speakers/2.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="centerstage-1">
          <div class="head py-4 text-center rounded">
            <h2>Fireside Chat Moderator</h2>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/chat moderator/popup/2')"
            >
              <img
                src="/speakers/desktop/chat moderator/2.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="panellist">
          <div class="head py-4 text-center rounded">
            <h2>PANELLISTS</h2>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/panellists/popup/3')"
            >
              <img
                src="/speakers/desktop/panellists/3.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/panellists/popup/1')"
            >
              <img
                src="/speakers/desktop/panellists/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/panellists/popup/2')"
            >
              <img
                src="/speakers/desktop/panellists/2.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/panellists/popup/4')"
            >
              <img
                src="/speakers/desktop/panellists/4.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/panellists/popup/5')"
            >
              <img
                src="/speakers/desktop/panellists/5.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="panellist">
          <div class="head py-4 text-center rounded">
            <h2>PANEL MODERATOR</h2>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/panel moderator/popup/1')"
            >
              <img
                src="/speakers/desktop/panel moderator/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="centerstage-2">
          <div class="head py-4 text-center rounded">
            <h2>Bridges that Have Been Built or Burnt</h2>
            <p>CENTRESTAGE SPEAKERS</p>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/centerstage 2/popup/1')"
            >
              <img
                src="/speakers/desktop/centerstage 2/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/centerstage 2/popup/2')"
            >
              <img
                src="/speakers/desktop/centerstage 2/2.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="centerstage-2">
          <div class="head py-4 text-center rounded">
            <h2>Fireside Chat Moderator</h2>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/chat moderator/popup/3')"
            >
              <img
                src="/speakers/desktop/chat moderator/3.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/chat moderator/popup/1')"
            >
              <img
                src="/speakers/desktop/chat moderator/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="debaters">
          <div class="head py-4 text-center rounded">
            <h2>FACEOFF CHAIRPERSON</h2>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/FaceOfChairman/popup/1')"
            >
              <img
                src="/speakers/desktop/FaceOfChairman/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="debaters">
          <div class="head py-4 text-center rounded">
            <h2>DEBATERS</h2>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/debaters/popup/2')"
            >
              <img
                src="/speakers/desktop/debaters/2.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/debaters/popup/4')"
            >
              <img
                src="/speakers/desktop/debaters/4.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/debaters/popup/5')"
            >
              <img
                src="/speakers/desktop/debaters/5.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/debaters/popup/6')"
            >
              <img
                src="/speakers/desktop/debaters/6.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/debaters/popup/7')"
            >
              <img
                src="/speakers/desktop/debaters/7.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/debaters/popup/8')"
            >
              <img
                src="/speakers/desktop/debaters/8.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="centerstage-3 mb-5">
          <div class="head py-4 text-center rounded">
            <h2>How Can you Build the Bridge</h2>
            <p>CENTRESTAGE SPEAKERS</p>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/How Can you Build the Bridge/popup/1')"
            >
              <img
                src="/speakers/desktop/How Can you Build the Bridge/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="panellist-2 ">
          <div class="head py-4 text-center rounded">
            <h2>Bringing Friends Forever</h2>
          </div>
          <div class="row py-5 justify-content-center">
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/colide panellists/popup/1')"
            >
              <img
                src="/speakers/desktop/colide panellists/1.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/colide panellists/popup/2')"
            >
              <img
                src="/speakers/desktop/colide panellists/2.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/colide panellists/popup/3')"
            >
              <img
                src="/speakers/desktop/colide panellists/1new3.png"
                class="img-fluid"
                alt=""
              />
            </div>
            <div
              class="col-sm-4 col-6 pointer text-center"
              @click="openModal('/speakers/desktop/colide panellists/popup/4')"
            >
              <img
                src="/speakers/desktop/colide panellists/4.png"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <div
      class="modal fade"
      id="popupspeaker"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content border-0" style="background: transparent">
          <div class="modal-body p-0">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="color: #000"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 align-self-center">
                  <img
                    alt="Dr Loh Yong Joo"
                    class="img-fluid"
                    id="popupspeakerImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import HeroVideo from "@/components/hero.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "speaker",
  components: {
    HeroVideo,
    Footer,
  },
  data() {
    return {
      type: "desktop",
      // current: "healthcare_innomatch",
      current: "flagship",

      currentImg: "POPUP/Dr Loh Yong Joo (w).png",
    };
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openModal(path) {
      let image = document.getElementById("popupspeakerImage");
      if (this.type === "desktop") {
        image.src = path + ".png";
        $("#popupspeaker").modal("show");
      } else {
        image.src = path + "-m.png";

        $("#popupspeaker").modal("show");
      }
    },

    onResize() {
      let mq = window.matchMedia("(max-width: 570px)");
      if (mq.matches) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.speaker {
  .pointer {
    cursor: pointer;
  }
  .speaker-text {
    box-shadow: 0px 1px 8px 10px #4a2c76;

    background-image: linear-gradient(
      135deg,
      rgb(127, 41, 254),
      rgb(217, 65, 103)
    );
    color: #fff;

    h2 {
      margin-bottom: 0px !important;
      font-family: "Sansrace-Regular";
    }
  }
  .content {
    background-image: linear-gradient(rgb(68, 11, 154), rgb(16, 4, 51));

    .goh {
      .head {
        background-color: #c93579;
        box-shadow: 0px 1px 8px 10px #4a2c76;
        color: #fff;
        h2 {
          margin-bottom: 0px !important;
          font-family: "Sansrace-Regular";
        }
      }
    }
    .host {
      .head {
        background-color: #c93579;
        box-shadow: 0px 1px 8px 10px #4a2c76;
        color: #fff;
        h2 {
          margin-bottom: 0px !important;
          font-family: "Sansrace-Regular";
        }
      }
    }

    .centerstage-1 {
      .head {
        background: linear-gradient(30deg, #fe41c5, #8d41fe);
        box-shadow: 0px 1px 8px 10px #4a2c76;
        color: #fff;
        h2 {
          margin-bottom: 0px !important;
          font-family: "Sansrace-Regular";
        }
        p {
          margin-bottom: 0px !important;
          font-family: "Nexa-Bold";
          font-size: 1.5rem;
        }
      }
    }
    .panellist {
      .head {
        background: linear-gradient(30deg, #fe41c5, #8d41fe);
        box-shadow: 0px 1px 8px 10px #4a2c76;
        color: #fff;
        h2 {
          margin-bottom: 0px !important;
          font-family: "Nexa-Bold";
        }
      }
    }
    .centerstage-2 {
      .head {
        background: linear-gradient(30deg, #22c1b2, #520db9);
        box-shadow: 0px 1px 8px 10px #4a2c76;
        color: #fff;
        h2 {
          margin-bottom: 0px !important;
          font-family: "Sansrace-Regular";
        }
        p {
          margin-bottom: 0px !important;
          font-family: "Nexa-Bold";
          font-size: 1.5rem;
        }
      }
    }
    .debaters {
      .head {
        background: linear-gradient(30deg, #22c1b2, #520db9);
        box-shadow: 0px 1px 8px 10px #4a2c76;
        color: #fff;
        h2 {
          margin-bottom: 0px !important;
          font-family: "Nexa-Bold";
        }
      }
    }

    .centerstage-3 {
      .head {
        background: linear-gradient(180deg, #eca961, #d94167);
        box-shadow: 0px 1px 8px 10px #4a2c76;
        color: #fff;
        h2 {
          margin-bottom: 0px !important;
          font-family: "Sansrace-Regular";
        }
        p {
          margin-bottom: 0px !important;
          font-family: "Nexa-Bold";

          font-size: 1.5rem;
        }
      }
    }

    .panellist-2 {
      .head {
        background: linear-gradient(180deg, #eca961, #d94167);

        box-shadow: 0px 1px 8px 10px #4a2c76;
        color: #fff;
        h2 {
          margin-bottom: 0px !important;
          font-family: "Nexa-Bold";
        }
      }
    }
  }
  .close {
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 999;
    font-size: 2rem;
    font-weight: normal;
    color: #fff;
    opacity: 1;
  }
  @media only screen and (max-width: 600px) {
    .close {
      position: absolute;
      right: 20px;
      top: 0px;
      z-index: 999;
      font-size: 2rem;
      font-weight: normal;
      color: #000;
      -webkit-text-stroke: 1px black;
      -webkit-text-fill-color: white;
    }
  }
}
</style>
